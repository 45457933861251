import React from "react";
import * as moment from "moment";
import WithFeed from "../Layouts/WithFeed";
import { graphql, PageProps } from "gatsby";
import { PostResults, Post } from "../Data/Post";
import { Helmet } from "react-helmet";


// eslint-disable-next-line import/no-default-export
export default function Blogger(props: PageProps<PostResults>)
{
    const post = props.data.allPost?.edges[0].node as Post;

    return <WithFeed
        title={post.title}
        subtitle={moment(post.created).format("dddd, MMMM Do YYYY")}
        showBack={true}
    >
        <Helmet
            title={post.title}
        />

        <div
            style={{
                width: "100%",
            }}
            dangerouslySetInnerHTML={{ __html: (
                post.parent?.html
                || post.internal.content
            ) }}
        />
    </WithFeed>;
}

export const query = graphql`
    query Posts($slug: String!) {
        allPost(filter: {slug: {eq: $slug}}) {
            edges {
                node {
                    id
                    parent {
                        ... on MarkdownRemark {
                            html
                            internal {
                                type
                            }
                        }
                    }
                    internal {
                        mediaType
                        type
                        owner
                        ignoreType
                        fieldOwners
                        description
                        contentDigest
                        content
                    }
                    slug
                    title
                    created
                    updated
                }
            }
        }
    }
`;
